import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { authGuard } from "./modules/Auth/services/auth.guard";
import { roleGuard } from "./modules/Auth/services/role.guard";

const routes: Routes = [
    { path: '', loadChildren: () => import('./modules/Home/home.module').then(m => m.HomeModule), canActivate: [authGuard] },
    { path: 'account', loadChildren: () => import('./modules/Auth/auth.module').then(m => m.AuthModule) },
    { path: 'report', loadChildren: () => import('./modules/Report/report.module').then(m => m.ReportModule), canActivate: [authGuard] },
    { path: 'compliance', loadChildren: () => import('./modules/Compliance/compliance.module').then(m => m.ComplianceModule), canActivate: [authGuard] },
    { path: 'bulk-export', loadChildren: () => import('./modules/BulkExport/bulk-export.module').then(m => m.BulkExportModule), canActivate: [authGuard]},
    { path: 'user-management', loadChildren: () => import('./modules/User-Management/user-management.module').then(m => m.UserManagementModule), canActivate: [authGuard, roleGuard], data: { admin: true } },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }